import React, { Component } from "react"
import contactFormStyles from "./contactForm.module.scss"
import Button from "../components/global/button"

export class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.contactForm = React.createRef()
    this.state = {
      form: {
        name: "",
        email: "",
        telephone: "",
        subject: "",
        message: "",
        sentMessage: "",
      },
      feedbackMessage: "",
    }
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const form = this.contactForm.current
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state.form,
      }),
    })
      .then(() => {
        this.setState({ feedbackMessage: "Erfolgreich versendet!" })
        this.setState({
          form: {
            name: "",
            email: "",
            telephone: "",
            subject: "",
            message: "",
          },
        })
      })
      .catch(error =>
        alert("Fehler! Form konnte nicht übermittelt werden: ", error)
      )
  }

  render() {
    return (
      <div className={contactFormStyles.formContainer}>
        <form
          name="contact"
          ref={this.contactForm}
          onSubmit={this.handleSubmit}
          method="post"
          data-netlify={true}
          data-netlify-honeypot="bot-field"
          autoComplete="off"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={contactFormStyles.formItem}>
            <label htmlFor="name">
              Name <span className={contactFormStyles.required}>*</span>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={this.state.form.name}
              onChange={this.handleInputChange}
              required={true}
              placeholder=" "
            />
          </div>
          <div className={contactFormStyles.formItem}>
            <label htmlFor="email">
              Email <span className={contactFormStyles.required}>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={this.state.form.email}
              onChange={this.handleInputChange}
              required={true}
              placeholder=" "
            />
          </div>
          <div className={contactFormStyles.formItem}>
            <label htmlFor="telephone">Telefon</label>
            <input
              type="tel"
              name="telephone"
              id="telephone"
              value={this.state.form.telephone}
              onChange={this.handleInputChange}
              placeholder=" "
              pattern="^[0-9]*$"
            />
          </div>
          <div className={contactFormStyles.formItem}>
            <label htmlFor="subject">Betreff</label>
            <input
              type="text"
              name="subject"
              id="subject"
              value={this.state.form.subject}
              onChange={this.handleInputChange}
              placeholder=" "
            />
          </div>
          <div className={contactFormStyles.formItem}>
            <label htmlFor="message">
              Nachricht <span className={contactFormStyles.required}>*</span>
            </label>
            <textarea
              name="message"
              id="message"
              value={this.state.form.message}
              onChange={this.handleInputChange}
              required={true}
              placeholder=" "
            />
          </div>
          <Button type="submit">Senden</Button>
          <div className={contactFormStyles.success}>
            {this.state.feedbackMessage}
          </div>
        </form>
      </div>
    )
  }
}

export default ContactForm
