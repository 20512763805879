import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import MapContainer from "../components/MapContainer"
import kontaktStyles from "../styles/kontakt.module.scss"

const KontaktPage = () => {
  return (
    <Layout>
      <SEO
        title="Kontakt"
        description="Innenarchitektur Schmitter | Volksdorfer Damm 272 | 22395 Hamburg | +494060566090 | info@schmitter-design.de"
      />
      <div className="container">
        {/* <div className={kontaktStyles.mapContainer}>
          <MapContainer />
        </div> */}
        <div className={kontaktStyles.formAdressContainer}>
          <div className={kontaktStyles.formContainer}>
            <h2>Kontaktieren Sie uns gerne!</h2>
            <ContactForm />
          </div>
          <address className={kontaktStyles.adressContainer}>
            <div>
              <h2>Anschrift</h2>
              <p>
                Innenarchitektur Schmitter <br />
                Volksdorfer Damm 272 <br />
                22395 Hamburg <br />
                <a href="tel:+494060566090">+49 40 60566090</a> <br />
                <a href="mailto:info@schmitter-design.de">
                  info@schmitter-design.de
                </a>
              </p>
            </div>
          </address>
        </div>
      </div>
    </Layout>
  )
}

export default KontaktPage
